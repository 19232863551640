import React, { useEffect } from "react";
import AutomaticTrading from "../../components/Education/AutomaticTrading/AutomaticTrading";
import Banner from "../../components/Education/Banner/Banner";
import ExplanationAbout from "../../components/Education/ExplanationAbout/ExplanationAbout";
import InvestmentStrategies from "../../components/Education/InvestmentStrategies/InvestmentStrategies";
import Newsletters from "../../components/Education/Newsletters/Newsletters";
import OrderTypes from "../../components/Education/OrderTypes/OrderTypes";
import SupportAndResistance from "../../components/Education/SupportAndResistance/SupportAndResistance";

const Education = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return (
        <div className="ovf-hidden">
            <Banner />
            <SupportAndResistance />
            <InvestmentStrategies />
            <OrderTypes />
            <ExplanationAbout />
            <AutomaticTrading />
            <Newsletters />
        </div>
    );
};

export default Education;
