import React from "react";
import classes from './InvestIn.module.scss';
import { useTranslation, Trans } from "react-i18next";
import Button from "../../GlobalComponents/Button/Button";

const InvestIn = () => {
    const { t } = useTranslation();
    return (
        <div className="container">
            <div className={classes.investIn}>
                <div className={[classes.title, 'font-80'].join(' ')}>
                    {t('invest_in_title')}
                </div>
                <div className={[classes.subtitle, 'font-48'].join(' ')}>
                    {t('invest_in_subtitle')}
                </div>
                <div className={[classes.text, 'font-24'].join(' ')}>
                    <Trans>{t('invest_in_text')}</Trans>
                </div>
                <div className={classes.btn}>
                    <Button>{t('btn_begin_here')}</Button>
                </div>
            </div>
        </div>
    );
};

export default InvestIn;
