import React from "react";
import Button from "../../GlobalComponents/Button/Button";
import classes from "./AnyQuestions.module.scss";
import { useTranslation } from "react-i18next";

const AnyQuestions = () => {
    const { t } = useTranslation();
    return (
        <div className={[classes.anyQuestionsContainer, "container"].join(" ")}>
            <div className={classes.anyQuestionsWrap}>
                <div className={classes.anyQuestionsTextWrap}>
                    <h3 className={[classes.anyQuestionsTitle, "font-64"].join(" ")}>
                        {t('any_questions_title')}
                    </h3>
                    <p className={[classes.anyQuestionsText, 'font-24'].join(" ")}>
                        {t('any_questions_text')}
                    </p>
                    <div className={classes.anyQuestionsFormWrap}>
                        <form>
                            <div className={classes.anyQuestionsInputWrap}>
                                <input
                                    className={[classes.anyQuestionsInput, "font-24"].join(" ")}
                                    type="text"
                                    placeholder={t('any_questions_placeholder_name')}
                                />
                            </div>
                            <div className={classes.anyQuestionsInputWrap}>
                                <input
                                    className={[classes.anyQuestionsInput, "font-24"].join(" ")}
                                    type="text"
                                    placeholder={t('any_questions_placeholder_email')}
                                />
                            </div>
                            <div className={classes.anyQuestionsInputWrap}>
                                <input
                                    className={[classes.anyQuestionsInput, "font-24"].join(" ")}
                                    type="text"
                                    placeholder={t('any_questions_placeholder_your_account')}
                                />
                            </div>
                            <div className={classes.anyQuestionsInputWrap}>
                                <input
                                    className={[classes.anyQuestionsInput, "font-24"].join(" ")}
                                    type="text"
                                    placeholder={t('any_questions_placeholder_message')}
                                />
                            </div>
                        </form>
                        <Button nolink="true" white="true" className={classes.button}>{t('btn_submit')}</Button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AnyQuestions;

