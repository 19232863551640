import React from "react";
import classes from './OnlineTrading.module.scss';
import { useTranslation } from "react-i18next";
import Button from "../../GlobalComponents/Button/Button";
import man from '../../../assets/img/MainPage/OnlineTrading/man.png';
import plate_left_01 from '../../../assets/img/MainPage/OnlineTrading/plate_left_01.png';
import plate_left_02 from '../../../assets/img/MainPage/OnlineTrading/plate_left_02.png';
import plate_left_03 from '../../../assets/img/MainPage/OnlineTrading/plate_left_03.png';
import plate_right_01 from '../../../assets/img/MainPage/OnlineTrading/plate_right_01.png';
import plate_right_02 from '../../../assets/img/MainPage/OnlineTrading/plate_right_02.png';
import plate_right_03 from '../../../assets/img/MainPage/OnlineTrading/plate_right_03.png';
import money_left_01 from '../../../assets/img/MainPage/OnlineTrading/money_left_01.png';
import money_left_02 from '../../../assets/img/MainPage/OnlineTrading/money_left_02.png';
import money_left_03 from '../../../assets/img/MainPage/OnlineTrading/money_left_03.png';
import money_right_01 from '../../../assets/img/MainPage/OnlineTrading/money_right_01.png';
import money_right_02 from '../../../assets/img/MainPage/OnlineTrading/money_right_02.png';
import money_right_03 from '../../../assets/img/MainPage/OnlineTrading/money_right_03.png';

import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import { useEffect } from "react";
import { useRef } from "react";
gsap.registerPlugin(ScrollTrigger);

const OnlineTrading = () => {
    const { t } = useTranslation();
    const contentRef = useRef(null);

    useEffect(() => {
        if (document.documentElement.clientWidth > 1220) {
            gsap.timeline().to("#onlineTradingPlatesLeft",
            {
                x: -70,
                scrollTrigger: {
                    trigger: contentRef.current,
                    start: "top bottom",
                    end: "center bottom",
                    scrub: true,
                }
            });
            gsap.timeline().to("#onlineTradingPlatesRight",
            {
                x: 70,
                ease: 'none',
                scrollTrigger: {
                    trigger: contentRef.current,
                    start: "top bottom",
                    end: "center bottom",
                    scrub: true,
                }
            });
            gsap.timeline().to(".onlineTradingMoneyItem",
            {
                x: 0,
                y: 0,
                scale: 1,
                ease: 'none',
                scrollTrigger: {
                    trigger: contentRef.current,
                    start: "top bottom",
                    end: "center bottom",
                    scrub: true,
                }
            });
        }
    }, []);

    return (
        <div className="container">
            <div className={classes.onlineTrading}>
                <div className={[classes.title, 'font-64'].join(' ')}>
                    {t('online_trading_title')}
                </div>
                <div className={[classes.subtitle, 'font-32'].join(' ')}>
                    {t('online_trading_subtitle')}
                </div>
                <div className={[classes.text, 'font-24'].join(' ')}>
                    {t('online_trading_text')}
                </div>
                <div className={classes.btn}>
                    <Button>{t('btn_begin_here')}</Button>
                </div>
                <div ref={contentRef} className={[classes.content, 'no-select'].join(' ')}>
                    <img className={classes.man} src={man} alt=''/>
                    <div className={classes.plates}>
                        <div id="onlineTradingPlatesLeft" className={classes.platesLeft}>
                            <img className={classes.plateLeft_01} src={plate_left_01} alt=''/>
                            <img className={classes.plateLeft_02} src={plate_left_02} alt=''/>
                            <img className={classes.plateLeft_03} src={plate_left_03} alt=''/>
                        </div>
                        <div id="onlineTradingPlatesRight" className={classes.platesRight}>
                            <img className={classes.plateRight_01} src={plate_right_01} alt=''/>
                            <img className={classes.plateRight_02} src={plate_right_02} alt=''/>
                            <img className={classes.plateRight_03} src={plate_right_03} alt=''/>
                        </div>
                    </div>
                    <div className={classes.money}>
                        <div className={classes.moneyLeft}>
                            <img className={[classes.moneyLeft_01, "onlineTradingMoneyItem"].join(' ')} src={money_left_01} alt=''/>
                            <img className={[classes.moneyLeft_02, "onlineTradingMoneyItem"].join(' ')} src={money_left_02} alt=''/>
                            <img className={[classes.moneyLeft_03, "onlineTradingMoneyItem"].join(' ')} src={money_left_03} alt=''/>
                        </div>
                        <div className={classes.moneyRight}>
                            <img className={[classes.moneyRight_01, "onlineTradingMoneyItem"].join(' ')} src={money_right_01} alt=''/>
                            <img className={[classes.moneyRight_02, "onlineTradingMoneyItem"].join(' ')} src={money_right_02} alt=''/>
                            <img className={[classes.moneyRight_03, "onlineTradingMoneyItem"].join(' ')} src={money_right_03} alt=''/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default OnlineTrading;
