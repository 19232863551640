import React from "react";
import classes from './ExplanationAbout.module.scss';
import image from '../../../assets/img/Education/explanation_about_image.png';
import Button from "../../GlobalComponents/Button/Button";
import { useTranslation } from "react-i18next";

const ExplanationAbout = () => {
    const { t } = useTranslation();
    return (
        <div className={classes.supportAndResistance}>
            <div className="container">
                <div className={classes.textContainer}>
                    <div className={[classes.title, 'font-64'].join(' ')}>
                        <div>
                            {t('expl_about_title_01')}
                        </div>
                        <div className={classes.subtitle}>
                            {t('expl_about_title_02')}
                        </div>
                    </div>
                    <div className={[classes.text, 'font-24'].join(' ')}>
                        <div>
                            {t('expl_about_text_01')}
                        </div>
                        <div>
                            {t('expl_about_text_02')}
                        </div>
                    </div>
                    <div className={classes.btn}>
                        <Button>{t('btn_begin_now')}</Button>
                    </div>
                </div>
            </div>
            <div className={classes.imageContainer}>
                <img className="no-select" src={image} alt=''/>
            </div>
        </div>
    );
};

export default ExplanationAbout;
