import React from "react";
import classes from './OrderTypes.module.scss';
import image from '../../../assets/img/Education/order_types_image.png';
import Button from "../../GlobalComponents/Button/Button";
import { useTranslation } from "react-i18next";

const OrderTypes = () => {
    const { t } = useTranslation();
    return (
        <div className={classes.supportAndResistance}>
            <div className="container">
                <div className={classes.textContainer}>
                    <div className={[classes.title, 'font-64'].join(' ')}>
                        {t('order_types_title')}
                    </div>
                    <div className={[classes.text, 'font-24'].join(' ')}>
                        {t('order_types_text')}
                    </div>
                    <div className={classes.btn}>
                        <Button>{t('btn_begin_now')}</Button>
                    </div>
                </div>
            </div>
            <div className={classes.imageContainer}>
                <img className="no-select" src={image} alt=''/>
            </div>
        </div>
    );
};

export default OrderTypes;
