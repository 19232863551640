import React, { useState } from "react";
import classes from './OpenAccount.module.scss';
import { Trans, useTranslation } from "react-i18next";
import Button from "../../GlobalComponents/Button/Button";
import icon_plus from '../../../assets/img/TradingSpace/icon_plus.png';
import icon_minus from '../../../assets/img/TradingSpace/icon_minus.png';

const tableRows = [
    'trading_space_open_an_account_col_01',
    'trading_space_open_an_account_col_02',
    'trading_space_open_an_account_col_03',
    'trading_space_open_an_account_col_04',
    'trading_space_open_an_account_col_05',
    'trading_space_open_an_account_col_06',
    'trading_space_open_an_account_col_07',
    'trading_space_open_an_account_col_08',
    'trading_space_open_an_account_col_09',
    'trading_space_open_an_account_col_10',
    'trading_space_open_an_account_col_11',
];

const tabs = [
    {
        title: 'Basic',
        price: '$250',
        rows: [
            'trading_space_open_an_account_01_01',
            'trading_space_open_an_account_01_02',
            'trading_space_open_an_account_01_03',
            true,
            true,
            true,
            true,
            false,
            true,
            false,
            false,
        ],
    },
    {
        title: 'Standard',
        price: '$1.000',
        rows: [
            false,
            'trading_space_open_an_account_02_02',
            'trading_space_open_an_account_02_03',
            true,
            true,
            true,
            true,
            true,
            true,
            false,
            false,
        ],
    },
    {
        title: 'Silver',
        price: '$5.000',
        rows: [
            false,
            'trading_space_open_an_account_03_02',
            'trading_space_open_an_account_03_03',
            true,
            true,
            true,
            true,
            true,
            true,
            true,
            false,
        ],
    },
    {
        title: 'Gold',
        price: '$25.000',
        rows: [
            false,
            'trading_space_open_an_account_04_02',
            'trading_space_open_an_account_04_03',
            true,
            true,
            true,
            true,
            true,
            true,
            true,
            false,
        ],
    },
    {
        title: 'Platinum',
        price: '$50.000',
        rows: [
            false,
            'trading_space_open_an_account_05_02',
            'trading_space_open_an_account_05_03',
            true,
            true,
            true,
            true,
            true,
            true,
            true,
            true,
        ],
    },
];

const OpenAccount = () => {
    const { t } = useTranslation();
    const [ currentTab, setCurrentTab ] = useState(0);
    return (
        <section className="container">
            <div className={classes.openAccount}>
                <h2 className={[classes.title, 'font-64'].join(' ')}>
                    {t('open_account_title')}
                </h2>
                <div className={classes.buttons}>
                    {tabs.map((tab, index) =>
                        <div 
                            key={index} 
                            className={`${classes.button} ${index === currentTab && classes.buttonActive} no-select`}
                            onClick={() => setCurrentTab(index)}
                        >
                            <p className={classes.buttonTitle}>
                                {tab.title}
                            </p>
                            <p className={classes.buttonPrice}>
                                {tab.price}
                            </p>
                        </div>
                    )}
                </div>
                <div className={classes.content}>
                    <p className={[classes.contentTitle, 'font-32'].join(' ')}>
                        {tabs[currentTab].title}
                    </p>
                    <div className={classes.tableWrap}>
                        <table>
                            <tbody className="font-24">
                                {tabs[currentTab].rows.map((col, index) =>
                                    <tr key={index}>
                                        <td>
                                            <Trans>
                                                {tableRows[index]}
                                            </Trans>
                                        </td>
                                        <td>
                                            {typeof(col) === 'string' ?
                                                <>
                                                    <Trans>
                                                        {col}
                                                    </Trans>
                                                </>
                                                :
                                                col === true ?
                                                    <img src={icon_plus} alt=''/>
                                                    :
                                                    <img src={icon_minus} alt=''/>
                                            }
                                        </td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    </div>
                    <div className={classes.btn}>
                        <Button>
                            {t('open_account_title')}
                        </Button>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default OpenAccount;
