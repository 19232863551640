import React, { useState } from "react";
import Button from "../../GlobalComponents/Button/Button";
import classes from "./Banner.module.scss";
import { useTranslation } from "react-i18next";
import Cookies from "js-cookie";

const Banner = () => {
  const { t } = useTranslation();
  const [hov, setHov] = useState(false);
  const lang = Cookies.get('i18next');

  return (
    <div className={classes.banner}>
      <div className={classes.bannerBody}>
        <div className="container">
          <div className={classes.bannerWrapper}>
            <div className={classes.bannerContent}>
              <div className={`${classes.bannerContentTitle} ${lang === 'en' ? 'font-80' : 'font-64'}`}>
                {t('about_banner_title')}
              </div>
              <div
                className={[classes.bannerContentSubtitle, "font-24"].join(" ")}
              >
                {t('about_banner_text')}
              </div>
              <div className={classes.bannerContentBtn}>
                <Button
                  white="true"
                  onMouseEnter={() => {
                    setHov(true);
                  }}
                  onMouseLeave={() => {
                    setHov(false);
                  }}
                >
                  {t('btn_start_trading')}
                </Button>
              </div>
            </div>
            <div
              className={
                hov
                  ? [classes.bannerWrapperArrow, classes.hover].join(" ")
                  : classes.bannerWrapperArrow
              }
            ></div>
            <div
              className={
                hov
                  ? [classes.bannerWrapperWolf, classes.hover].join(" ")
                  : classes.bannerWrapperWolf
              }
            ></div>
            <div className={classes.sphereBg}></div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Banner;
