import React from "react";
import classes from './Banner.module.scss';
import { useTranslation } from "react-i18next";
import arrow from '../../../assets/img/Education/banner_arrow.png';
import bull from '../../../assets/img/Education/banner_bull.png';
import Button from '../../GlobalComponents/Button/Button';
import Cookies from "js-cookie";

const Banner = () => {
    const { t } = useTranslation();
    const lang = Cookies.get('i18next');

    return (
        <div className={["container", classes.container].join(' ')}>
            <div className={classes.banner}>
                <div className={`${classes.title} ${lang === 'en' ? 'font-80' : 'font-64'}`}>
                    {t('education_banner_title')}
                </div>
                <div className={[classes.text, 'font-24'].join(' ')}>
                    {t('education_banner_text')}
                </div>
                <div className={classes.btn}>
                    <Button white="true">{t('btn_begin_here')}</Button>
                </div>
                <div className={classes.sphereBg}></div>
                <img className={[classes.arrow, 'no-select'].join(' ')} src={arrow} alt=''/>
                <img className={[classes.bull, 'no-select'].join(' ')} src={bull} alt=''/>
            </div>
        </div>
    );
};

export default Banner;
