import MainPage from "../pages/MainPage/MainPage";
import BeginNow from "../pages/BeginNow/BeginNow";
import TradingSpace from "../pages/TradingSpace/TradingSpace";
import AboutUs from "../pages/AboutUs/AboutUs";
import Education from "../pages/Education/Education";

export const routes = [
    { path: '/', element: MainPage },
    { path: '/begin', element: BeginNow },
    { path: '/trading', element: TradingSpace },
    { path: '/about', element: AboutUs },
    { path: '/education', element: Education },
];

export const headerRoutes = [
    { id: 1, path: '/begin', text: 'header_begin_now' },
    { id: 2, path: '/trading', text: 'header_trading_space' },
    { id: 3, path: '/education', text: 'header_education' },
    { id: 4, path: '/about', text: 'header_about_us' },
];
