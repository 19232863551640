import React from "react";
import classes from './OpenAnAccount.module.scss';
import { useTranslation } from "react-i18next";
import Button from "../../GlobalComponents/Button/Button";

const OpenAnAccount = () => {
    const { t } = useTranslation();
    return (
        <div className="container">
            <div className={classes.openAnAccount}>
                <div className={[classes.title, 'font-64'].join(' ')}>
                    {t('open_an_account_title')}
                </div>
                <div className={[classes.subtitle, 'font-48'].join(' ')}>
                    {t('open_an_account_subtitle')}
                </div>
                <div className={[classes.text, 'font-24'].join(' ')}>
                    {t('open_an_account_text')}
                </div>
                <div className={classes.btn}>
                    <Button>{t('btn_open_account')}</Button>
                </div>
            </div>
        </div>
    );
};

export default OpenAnAccount;
