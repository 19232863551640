import React from "react";
import classes from './TheBest.module.scss';
import { useTranslation } from "react-i18next";
import Cookies from "js-cookie";
import chart from '../../../assets/img/MainPage/TheBest/chart.png';
import phone from '../../../assets/img/MainPage/TheBest/phone.png';
import icon_amazon from '../../../assets/img/MainPage/TheBest/icon_amazon.png';
import icon_google from '../../../assets/img/MainPage/TheBest/icon_google.png';
import icon_netflix from '../../../assets/img/MainPage/TheBest/icon_netflix.png';
import icon_twitter from '../../../assets/img/MainPage/TheBest/icon_twitter.png';

const TheBest = () => {
    const { t } = useTranslation();
    const lang = Cookies.get('i18next');
    return (
        <div className={["container", classes.cont].join(' ')}>
            <div className={classes.theBest}>
                <div className={classes.textContainer}>
                    <div className={`${classes.title} ${lang === 'en' ? 'font-64' : 'font-48'}`}>
                        {t('the_best_title')}
                    </div>
                    <div className={[classes.subtitle, 'font-48'].join(' ')}>
                        {t('the_best_subtitle')}
                    </div>
                    <div className={[classes.text, 'font-24'].join(' ')}>
                        {t('the_best_text')}
                    </div>
                </div>
                <img className={[classes.chart, 'no-select'].join(' ')} src={chart} alt=''/>
                <img className={[classes.phone, 'no-select'].join(' ')} src={phone} alt=''/>
                <div className={[classes.icons, 'no-select'].join(' ')}>
                    <img className={classes.icon_google} src={icon_google} alt=''/>
                    <img className={classes.icon_netflix} src={icon_netflix} alt=''/>
                    <img className={classes.icon_amazon} src={icon_amazon} alt=''/>
                    <img className={classes.icon_twitter} src={icon_twitter} alt=''/>
                </div>
            </div>
        </div>
    );
};

export default TheBest;