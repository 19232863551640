import React, { useEffect, useRef } from "react";
import classes from './Banner.module.scss';
import { Trans, useTranslation } from "react-i18next";
import Cookies from "js-cookie";
import Button from '../../GlobalComponents/Button/Button';

import map from '../../../assets/img/MainPage/Banner/map.png';
import chart from '../../../assets/img/MainPage/Banner/chart.png';
import bottom_floor from '../../../assets/img/MainPage/Banner/bottom_floor.png';
import tower_left_with_trees from '../../../assets/img/MainPage/Banner/tower_left_with_trees.png';
import tower_left_towers from '../../../assets/img/MainPage/Banner/tower_left_towers.png';
import tower_left_hight from '../../../assets/img/MainPage/Banner/tower_left_hight.png';
import trees from '../../../assets/img/MainPage/Banner/trees.png';
import tower_right_towers from '../../../assets/img/MainPage/Banner/tower_right_towers.png';
import tower_right_tower from '../../../assets/img/MainPage/Banner/tower_right_tower.png';
import bull from '../../../assets/img/MainPage/Banner/bull.png';
import bear from '../../../assets/img/MainPage/Banner/bear.png';

import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import { CustomEase } from 'gsap/src/all';
gsap.registerPlugin(ScrollTrigger);
gsap.registerPlugin(CustomEase);


const Banner = () => {
    const { t } = useTranslation();
    const lang = Cookies.get('i18next');
    const banner = useRef(null);
    const textFirst = useRef(null);
    const textFirstWr = useRef(null);
    const textSecond = useRef(null);
    const textSecondWr = useRef(null);
    const mapWr = useRef(null);
    const mapImg = useRef(null);
    const chartWr = useRef(null);
    const chartImg = useRef(null);
    const bottomFloorWr = useRef(null);
    const bottomFloorImg = useRef(null);

    const leftTreesWr = useRef(null);
    const leftTreesImg = useRef(null);
    const towerLeftWithTreesWr = useRef(null);
    const towerLeftWithTreesImg = useRef(null);
    const towerLeftTowersWr = useRef(null);
    const towerLeftTowersWr2 = useRef(null);
    const towerLeftTowersImg = useRef(null);
    const towerLeftHightWr = useRef(null); 
    const towerLeftHightWr2 = useRef(null); 
    const towerLeftHightImg = useRef(null);
    const towerRightTowersWr = useRef(null);
    const towerRightTowersWr2 = useRef(null);
    const towerRightTowersImg = useRef(null);
    const towerRightTowerWr = useRef(null);
    const towerRightTowerImg = useRef(null);
    const bullWr = useRef(null);
    const bullWr2 = useRef(null);
    const bullImg = useRef(null);
    const bearWr = useRef(null);
    const bearWr2 = useRef(null);
    const bearImg = useRef(null);

    useEffect(() => {
        if (document.documentElement.clientWidth > 1220) {
            /**
             *      START
            */
    
            // first text
            gsap.to(textFirst.current, {
                opacity: 1,
                y: 0,
    
                ease: 4,
                duration: 2,
                delay: 1,
            });
            gsap.to(mapWr.current, {
                opacity: 1,
                y: 0,
    
                ease: 4,
                duration: 2,
                delay: 1,
            });
            gsap.to(chartWr.current, {
                opacity: 1,
                y: 0,
    
                ease: 4,
                duration: 2,
                delay: 1.5,
            });
            gsap.to(towerLeftWithTreesWr.current, {
                scale: 1,
                y: 0,
    
                ease: 4,
                duration: 2,
                delay: 1.5,
            });
            gsap.to(towerLeftTowersWr.current, {
                scale: 1,
                y: 0,
                x: 0,
    
                ease: 4,
                duration: 2,
                delay: 1.5,
            });
            gsap.to(towerLeftHightWr.current, {
                scale: 1,
                y: 0,
                x: 0,
    
                ease: 4,
                duration: 2,
                delay: 1.5,
            });
    
            gsap.to(towerRightTowerWr.current, {
                scale: 1,
                y: 0,
                x: 0,
    
                ease: 4,
                duration: 2,
                delay: 1.5,
            });
            gsap.to(towerRightTowersWr.current, {
                scale: 1,
                y: 0,
                x: 0,
    
                ease: 4,
                duration: 2,
                delay: 1.5,
            });
    
    
            gsap.to(bullWr.current, {
                scale: 1,
                y: 0,
                x: 0,
    
                ease: 4,
                duration: 2,
                delay: 1.5,
            });
            gsap.to(bearWr.current, {
                scale: 1,
                y: 0,
                x: 0,
    
                ease: 4,
                duration: 2,
                delay: 1.5,
            });
            
    
            /**
             *      SLIDES CHANGING
            */
    
            // left trees
            gsap.timeline().to(leftTreesWr.current,
            {           
                x: -50,
                ease: CustomEase.create("custom", "M0,0 C0,0 0.018,0 0.1,0 0.244,0 0.438,0.354 0.492,0.466 0.53,0.542 0.598,0.671 0.63,0.738 0.656,0.792 0.687,0.862 0.718,0.908 0.748,0.952 0.81,1 0.83,1 0.854,1 0.838,1 0.872,1 0.902,1 0.906,1 0.934,1 0.96,1 1,1 1,1"),
                scrollTrigger: {
                    trigger: banner.current,     
                    start: "top top",
                    end: "+=2500px",
                    scrub: 3,
                    pin: banner.current,
                }
            });
            // tower left with trees
            gsap.timeline().to(towerLeftWithTreesImg.current,
            {           
                width: '-=30px',
                ease: CustomEase.create("custom", "M0,0 C0,0 0.018,0 0.1,0 0.244,0 0.438,0.354 0.492,0.466 0.53,0.542 0.598,0.671 0.63,0.738 0.656,0.792 0.687,0.862 0.718,0.908 0.748,0.952 0.81,1 0.83,1 0.854,1 0.838,1 0.872,1 0.902,1 0.906,1 0.934,1 0.96,1 1,1 1,1"),
                scrollTrigger: {
                    trigger: banner.current,     
                    start: "top top",
                    end: "+=2500px",
                    scrub: 3,
                    pin: banner.current,
                }
            });
            // tower left towers
            gsap.timeline().to(towerLeftTowersImg.current,
            {           
                x: -40,
                ease: CustomEase.create("custom", "M0,0 C0,0 0.018,0 0.1,0 0.244,0 0.438,0.354 0.492,0.466 0.53,0.542 0.598,0.671 0.63,0.738 0.656,0.792 0.687,0.862 0.718,0.908 0.748,0.952 0.81,1 0.83,1 0.854,1 0.838,1 0.872,1 0.902,1 0.906,1 0.934,1 0.96,1 1,1 1,1"),
                scrollTrigger: {
                    trigger: banner.current,     
                    start: "top top",
                    end: "+=2500px",
                    scrub: 3,
                    pin: banner.current,
                }
            });
            // tower left hight
            gsap.timeline().to(towerLeftHightImg.current,
            {           
                x: -20,
                ease: CustomEase.create("custom", "M0,0 C0,0 0.018,0 0.1,0 0.244,0 0.438,0.354 0.492,0.466 0.53,0.542 0.598,0.671 0.63,0.738 0.656,0.792 0.687,0.862 0.718,0.908 0.748,0.952 0.81,1 0.83,1 0.854,1 0.838,1 0.872,1 0.902,1 0.906,1 0.934,1 0.96,1 1,1 1,1"),
                scrollTrigger: {
                    trigger: banner.current,     
                    start: "top top",
                    end: "+=2500px",
                    scrub: 3,
                    pin: banner.current,
                }
            });
            // tower right tower
            gsap.timeline().to(towerRightTowerWr.current,
            {           
                width: '-=120px',
                ease: CustomEase.create("custom", "M0,0 C0,0 0.018,0 0.1,0 0.244,0 0.438,0.354 0.492,0.466 0.53,0.542 0.598,0.671 0.63,0.738 0.656,0.792 0.687,0.862 0.718,0.908 0.748,0.952 0.81,1 0.83,1 0.854,1 0.838,1 0.872,1 0.902,1 0.906,1 0.934,1 0.96,1 1,1 1,1"),
                scrollTrigger: {
                    trigger: banner.current,     
                    start: "top top",
                    end: "+=2500px",
                    scrub: 3,
                    pin: banner.current,
                }
            });
            // tower right towers
            gsap.timeline().to(towerRightTowersImg.current,
            {           
                scale: 0.9,
                x: 50,
                ease: CustomEase.create("custom", "M0,0 C0,0 0.018,0 0.1,0 0.244,0 0.438,0.354 0.492,0.466 0.53,0.542 0.598,0.671 0.63,0.738 0.656,0.792 0.687,0.862 0.718,0.908 0.748,0.952 0.81,1 0.83,1 0.854,1 0.838,1 0.872,1 0.902,1 0.906,1 0.934,1 0.96,1 1,1 1,1"),
                scrollTrigger: {
                    trigger: banner.current,     
                    start: "top top",
                    end: "+=2500px",
                    scrub: 3,
                    pin: banner.current,
                }
            });
    
    
    
            // first text
            gsap.timeline().fromTo(textFirstWr.current,
            { 
                y: 0,
            }, 
            {           
                y: 1500,            
                // ease: CustomEase.create("custom", "M0,0 C0,0 0.148,0 0.23,0 0.374,0 0.43,0.429 0.456,0.526 0.476,0.602 0.524,0.754 0.552,0.822 0.574,0.876 0.574,0.883 0.602,0.93 0.628,0.974 0.637,1 0.68,1 0.732,1 0.748,1 0.8,1 0.858,1 0.821,1 0.882,1 1.016,1 1,1 1,1"),
                ease: CustomEase.create("custom", "M0,0 C0,0 0.018,0 0.1,0 0.244,0 0.438,0.354 0.492,0.466 0.53,0.542 0.598,0.671 0.63,0.738 0.656,0.792 0.687,0.862 0.718,0.908 0.748,0.952 0.81,1 0.83,1 0.854,1 0.838,1 0.872,1 0.902,1 0.906,1 0.934,1 0.96,1 1,1 1,1"),
                scrollTrigger: {
                    trigger: banner.current,     
                    start: "top top",
                    end: "+=2500px",
                    scrub: 3,
                    pin: banner.current,
                }
            });
            // second text
            gsap.timeline().fromTo(textSecondWr.current,
            { 
                y: 1500,
            }, 
            {           
                y: 0,            
                ease: CustomEase.create("custom", "M0,0,C0,0,0.004,0,0.086,0,0.23,0,0.27,0.242,0.304,0.358,0.326,0.458,0.387,0.639,0.41,0.71,0.432,0.78,0.473,0.882,0.51,0.924,0.548,0.968,0.566,1,0.614,1,0.642,1,0.838,1,0.872,1,0.902,1,0.906,1,0.934,1,0.96,1,1,1,1,1"),
                scrollTrigger: {
                    trigger: banner.current,     
                    start: "top top",
                    end: "+=2500px",
                    scrub: 3,
                    pin: banner.current,
                }
            });

            // bull
            gsap.timeline().to(bullWr2.current,
            {           
                y: -10,
                x: 20,            
                ease: CustomEase.create("custom", "M0,0 C0,0 0.018,0 0.1,0 0.244,0 0.438,0.354 0.492,0.466 0.53,0.542 0.598,0.671 0.63,0.738 0.656,0.792 0.687,0.862 0.718,0.908 0.748,0.952 0.81,1 0.83,1 0.854,1 0.838,1 0.872,1 0.902,1 0.906,1 0.934,1 0.96,1 1,1 1,1"),
                scrollTrigger: {
                    trigger: banner.current,     
                    start: "top top",
                    end: "+=2500px",
                    scrub: 3,
                    pin: banner.current,
                }
            });
            // bear
            gsap.timeline().to(bearWr2.current,
            {           
                y: -10,
                // x: -30,            
                scale: 1.05,
                ease: CustomEase.create("custom", "M0,0 C0,0 0.018,0 0.1,0 0.244,0 0.438,0.354 0.492,0.466 0.53,0.542 0.598,0.671 0.63,0.738 0.656,0.792 0.687,0.862 0.718,0.908 0.748,0.952 0.81,1 0.83,1 0.854,1 0.838,1 0.872,1 0.902,1 0.906,1 0.934,1 0.96,1 1,1 1,1"),
                scrollTrigger: {
                    trigger: banner.current,     
                    start: "top top",
                    end: "+=2500px",
                    scrub: 3,
                    pin: banner.current,
                }
            });
            
            
            /**
             *      EXIT
            */
    
            // left trees
            gsap.timeline().to(leftTreesImg.current,
            {           
                x: -150,
                ease: 'none',
                scrollTrigger: {
                    start: "+=2400px",
                    end: "+=2500px",
                    scrub: 3,
                }
            });
            // tower left with trees
            gsap.timeline().to(towerLeftWithTreesImg.current,
            {           
                x: -200,
                ease: 'none',
                scrollTrigger: {
                    start: "+=2400px",
                    end: "+=2500px",
                    scrub: 3,
                }
            });
            // tower left towers
            gsap.timeline().to(towerLeftTowersWr2.current,
            {           
                // right: -200,
                x: -230,
                ease: 'none',
                scrollTrigger: {
                    start: "+=2400px",
                    end: "+=2500px",
                    scrub: 3,
                }
            });
            // tower hight
            gsap.timeline().to(towerLeftHightWr2.current,
            {           
                // right: -200,
                x: -120,
                ease: 'none',
                scrollTrigger: {
                    start: "+=2400px",
                    end: "+=2500px",
                    scrub: 3,
                }
            });
            // tower right tower
            gsap.timeline().to(towerRightTowerImg.current,
            {           
                x: 420,
                ease: 'none',
                scrollTrigger: {
                    start: "+=2400px",
                    end: "+=2500px",
                    scrub: 3,
                }
            });
            // tower right towers
            gsap.timeline().to(towerRightTowersWr2.current,
            {           
                x: 320,
                ease: 'none',
                scrollTrigger: {
                    start: "+=2400px",
                    end: "+=2500px",
                    scrub: 3,
                }
            });

            // bull
            gsap.timeline().to(bullImg.current,
            {           
                width: '-=300px',
                ease: 'none',
                scrollTrigger: {
                    start: "+=2400px",
                    end: "+=2500px",
                    scrub: 3,
                }
            });
            // bull
            gsap.timeline().to(bearImg.current,
            {           
                width: '-=300px',
                x: 200,
                ease: 'none',
                scrollTrigger: {
                    start: "+=2400px",
                    end: "+=2500px",
                    scrub: 3,
                }
            });
    
            // floor
            gsap.timeline().fromTo(bottomFloorWr.current,
            { 
                scale: 1,
            }, 
            {           
                scale: 1.1,
                ease: 'none',
                scrollTrigger: {
                    start: "+=2500px",
                    end: "+=2600px",
                    scrub: 3,
                }
            });
        }
    }, []);
    
    return (
        <div id='bannerMainPageWr' ref={banner} className={classes.banner}>
            <div ref={textFirstWr} className={[classes.textFirstWr, classes.textBlock].join(' ')}>
                <div ref={textFirst} className={classes.textFirst}>
                    <div className={`${classes.subtitle} ${lang === 'en' ? 'font-48' : 'font-40'}`}>
                        {t('main_banner_subtitle')}
                    </div>
                    <div className={`${classes.title} ${lang === 'en' ? 'font-75' : 'font-64'}`}>
                        {t('main_banner_title')}
                    </div>
                    <div className={`${classes.text} ${lang === 'en' ? 'font-24' : 'font-20-langs'}`}>
                        <Trans>{t('main_banner_text')}</Trans>
                    </div>
                    <div className={classes.btn}>
                        <Button>{t('btn_begin_here')}</Button>
                    </div>
                </div>
            </div>
            <div ref={textSecondWr} className={[classes.textSecondWr, classes.textBlock].join(' ')}>
                <div ref={textSecond} className={classes.textSecond}>
                    <div className={`${classes.title} ${lang === 'en' ? 'font-75' : 'font-48'}`}>
                        {t('invest_in_title')}
                    </div>
                    <div className={`${classes.subtitle} ${lang === 'en' ? 'font-40' : 'font-40'}`}>
                        {t('invest_in_subtitle')}
                    </div>
                    <div className={`${classes.text} ${lang === 'en' ? 'font-24' : 'font-20-langs'}`}>
                        <Trans>{t('invest_in_text')}</Trans>
                    </div>
                    <div className={classes.btn}>
                        <Button>{t('btn_begin_here')}</Button>
                    </div>
                </div>
            </div>

            <div ref={mapWr} className={[classes.mapWr, 'no-select'].join(' ')}>
                <img ref={mapImg} className={classes.mapImg} src={map} alt=''/>
            </div>
            <div ref={chartWr} className={[classes.chartWr, 'no-select'].join(' ')}>
                <img ref={chartImg} className={classes.chartImg} src={chart} alt=''/>
            </div>
            <div ref={bottomFloorWr} className={[classes.bottomFloorWr, 'no-select'].join(' ')}>
                <img ref={bottomFloorImg} className={classes.bottomFloorImg} src={bottom_floor} alt=''/>
            </div>

            <div ref={leftTreesWr} className={[classes.leftTreesWr, 'no-select'].join(' ')}>
                <img ref={leftTreesImg} className={classes.leftTreesImg} src={trees} alt=''/>
            </div>
            <div ref={towerLeftWithTreesWr} className={[classes.towerLeftWithTreesWr, 'no-select'].join(' ')}>
                <img ref={towerLeftWithTreesImg} className={classes.towerLeftWithTreesImg} src={tower_left_with_trees} alt=''/>
            </div>
            <div ref={towerLeftTowersWr} className={[classes.towerLeftTowersWr, 'no-select'].join(' ')}>
                <div ref={towerLeftTowersWr2}>
                    <img ref={towerLeftTowersImg} className={classes.towerLeftTowersImg} src={tower_left_towers} alt=''/>
                </div>
            </div>
            <div ref={towerLeftHightWr} className={[classes.towerLeftHightWr, 'no-select'].join(' ')}>
                <div ref={towerLeftHightWr2}>
                    <img ref={towerLeftHightImg} className={classes.towerLeftHightImg} src={tower_left_hight} alt=''/>
                </div>
            </div>
            <div ref={towerRightTowerWr} className={[classes.towerRightTowerWr, 'no-select'].join(' ')}>
                <img ref={towerRightTowerImg} className={classes.towerRightTowerImg} src={tower_right_tower} alt=''/>
            </div>
            <div ref={towerRightTowersWr} className={[classes.towerRightTowersWr, 'no-select'].join(' ')}>
                <div ref={towerRightTowersWr2}>
                    <img ref={towerRightTowersImg} className={classes.towerRightTowersImg} src={tower_right_towers} alt=''/>
                </div>
            </div>

            <div ref={bullWr} className={[classes.bullWr, 'no-select'].join(' ')}>
                <div ref={bullWr2}>
                    <img ref={bullImg} className={classes.bullImg} src={bull} alt=''/>
                </div>
            </div>
            <div ref={bearWr} className={[classes.bearWr, 'no-select'].join(' ')}>
                <div ref={bearWr2}>
                    <img ref={bearImg} className={classes.bearImg} src={bear} alt=''/>
                </div>
            </div>
        </div>
    );
};

export default Banner;
