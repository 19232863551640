import React, { useState, useEffect, useRef } from "react";
import classes from './SupportAndResistance.module.scss';
import image from '../../../assets/img/Education/support_resistance_image.png';
import $ from 'jquery';
import { useTranslation } from "react-i18next";

const SupportAndResistance = () => {
    const { t } = useTranslation();
    const tab_01_ref = useRef(null);
    const tab_02_ref = useRef(null);
    const [ tab01Active, setTab01Active ] = useState(false);
    const [ tab02Active, setTab02Active ] = useState(false);
    useEffect(() => {
        $(tab_01_ref.current).slideUp(0);
        $(tab_02_ref.current).slideUp(0);
    }, []);
    return (
        <div className={classes.supportAndResistance}>
            <div className={classes.header}>
                <div className="container">
                    <div className={classes.textContainer}>
                        <div className={[classes.title, 'font-64'].join(' ')}>
                            {t('support_and_resistance_title')}
                        </div>
                        <div className={[classes.text, 'font-24'].join(' ')}>
                            {t('support_and_resistance_text')}
                        </div>
                    </div>
                </div>
                <div className={classes.imageContainer}>
                    <img className="no-select" src={image} alt=''/>
                </div>
            </div>
            <div className="container">
                <div className={[classes.subtitle, 'font-40'].join(' ')}>
                    {t('support_and_resistance_subtitle')}
                </div>
                <div className={[classes.text_02, 'font-24'].join(' ')}>
                    {t('support_and_resistance_text_02')}
                </div>
                <div className={classes.tabs}>
                    <div 
                        className={classes.tab}
                        onClick={() => {
                            $(tab_01_ref.current).slideToggle(400);
                            setTab01Active(value => !value);
                        }}    
                    >
                        <div className={classes.tabTitle}>
                            <div className={classes.tabTitleText}>
                                {t('support_and_resistance_tab_title_01')}
                            </div>
                            <div className={`${classes.tabTitleStatus} ${tab01Active && classes.activeStatus}`}></div>
                        </div>
                        <div ref={tab_01_ref} className={[classes.tabContent, 'font-24'].join(' ')}>
                            <div>
                                {t('support_and_resistance_tab_text_01')}
                            </div>
                        </div>
                    </div>
                    <div 
                        className={classes.tab}
                        onClick={() => {
                            $(tab_02_ref.current).slideToggle(400);
                            setTab02Active(value => !value);
                        }}    
                    >
                        <div className={classes.tabTitle}>
                            <div className={classes.tabTitleText}>
                                {t('support_and_resistance_tab_title_02')}
                            </div>
                            <div className={`${classes.tabTitleStatus} ${tab02Active && classes.activeStatus}`}></div>
                        </div>
                        <div ref={tab_02_ref} className={[classes.tabContent, 'font-24'].join(' ')}>
                            <div>
                                {t('support_and_resistance_tab_text_02')}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SupportAndResistance;
