import React from "react";
import classes from './OurStory.module.scss';
import { useTranslation } from "react-i18next";

const OurStory = () => {
    const { t } = useTranslation();
    return (
        <section className="container">
            <div className={classes.ourStory}>
                <h2 className={[classes.title, 'font-64'].join(' ')}>
                    Our Story
                </h2>
                <div className={[classes.cols, 'font-24'].join(' ')}>
                    <div className={classes.col}>
                        <p>
                            Lloyds Investing Group is a prominent financial conglomerate based in the United Kingdom. It emerged in 2009 through the merger of Lloyds TSB Group, a leading financial institution with a rich history dating back to the 18th century. In 2010, a decision was made to establish a company catering to investors, providing a comprehensive range of financial management tools and capital growth opportunities. Thus, the Lloyds Investing Group project was born.
                        </p>
                        <p>
                            One of the early pioneers of Lloyds Investing Group was Winfried Bischoff, who served as the chairman from 2009 to 2014. With a vast knowledge base and a significant portion of Lloyds' capital, Bischoff ensured the future success of the Lloyds Investing Group project. However, true investors find fulfillment not just in monetary gains but also in achieving victories. Bischoff continued to invest in various assets, multiplying Lloyds Invest's capital.
                        </p>
                    </div>
                    <div className={classes.col}>
                        <p>
                            At a certain point, he realized that brokers predominantly prefer to work with investors who allocate substantial capital. This preference allows brokers to earn substantial commissions, while ordinary individuals often fall victim to pseudo-markets that merely mimic brokers' work, solely focusing on the clients' money. This capitalist injustice deprived Bischoff of proper sleep for several months.
                        </p>
                        <p>
                            In 2016, Norman Blackwell took over as the chairman from 2014 to 2020. He made a significant decision to establish a hedge fund that prioritized the quantity, rather than the volume, of investments. Assembling a team of specialists, on March 8, 2016, Lloyds Investing Group achieved global recognition and captured the attention of investors worldwide.  
                        </p>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default OurStory;