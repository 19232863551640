import React, { useState } from "react";
import classes from "./Stock.module.scss";
import img1 from "../../../assets/img/BeginPage/3/1.png";
import img2 from "../../../assets/img/BeginPage/3/2.png";
import img3 from "../../../assets/img/BeginPage/3/3.png";
import img4 from "../../../assets/img/BeginPage/3/4.png";
import img5 from "../../../assets/img/BeginPage/3/5.png";
import img6 from "../../../assets/img/BeginPage/3/6.png";
import img7 from "../../../assets/img/BeginPage/3/7.png";
import { useTranslation } from "react-i18next";

const Stock = () => {
  const { t } = useTranslation();
  const [square, setSquare] = useState({ pos: 20, toggle: false });
  const [info, setInfo] = useState([
    {
      id: 0,
      active: true,
      leftPos: false,
      centerPos: true,
      rightPos: false,
      title: "the_stock_market_tab_title_02",
      btnText: "the_stock_market_tab_btn_02",
      img: img1,
      content: [
        "the_stock_market_tab_text_02_01",
        "the_stock_market_tab_text_02_02",
        [
          "the_stock_market_tab_li_02_01",
          "the_stock_market_tab_li_02_02",
          "the_stock_market_tab_li_02_03",
          "the_stock_market_tab_li_02_04",
          "the_stock_market_tab_li_02_05",
          "the_stock_market_tab_li_02_06",
        ],
      ],
    },
    {
      id: 1,
      active: false,
      leftPos: true,
      centerPos: false,
      rightPos: false,
      title: "the_stock_market_tab_title_01",
      btnText: "the_stock_market_tab_btn_01",
      img: img2,
      content: [
        "the_stock_market_tab_text_01",
      ],
    },
    {
      id: 2,
      active: false,
      leftPos: true,
      centerPos: false,
      rightPos: false,
      title: "the_stock_market_tab_title_03",
      btnText: "the_stock_market_tab_btn_03",
      img: img3,
      content: [
        "the_stock_market_tab_text_03",
      ],
    },
    {
      id: 3,
      active: false,
      leftPos: true,
      centerPos: false,
      rightPos: false,
      title: "the_stock_market_tab_title_04",
      btnText: "the_stock_market_tab_btn_04",
      img: img4,
      content: [
        "the_stock_market_tab_text_04",
      ],
    },
    {
      id: 4,
      active: false,
      leftPos: true,
      centerPos: false,
      rightPos: false,
      title: "the_stock_market_tab_title_05",
      btnText: "the_stock_market_tab_btn_05",
      img: img5,
      content: [
        "the_stock_market_tab_text_05_01",
        "the_stock_market_tab_text_05_02",
      ],
    },
    {
      id: 5,
      active: false,
      leftPos: true,
      centerPos: false,
      rightPos: false,
      title: "the_stock_market_tab_title_06",
      btnText: "the_stock_market_tab_btn_06",
      img: img6,
      content: [
        "the_stock_market_tab_text_06_01",
        "the_stock_market_tab_text_06_02",
      ],
    },
    {
      id: 6,
      active: false,
      leftPos: true,
      centerPos: false,
      rightPos: false,
      title: "the_stock_market_tab_title_07",
      btnText: "the_stock_market_tab_btn_07",
      img: img7,
      content: [
        "the_stock_market_tab_text_07_01",
        "the_stock_market_tab_text_07_02",
      ],
    },
  ]);

  return (
    <div className={classes.stock1}>
      <div className={classes.stockBody}>
        <div className="container">
          <div className={[classes.stockTitle, "font-64"].join(" ")}>
            {t('the_stock_market_title')}
          </div>
          <div className={classes.stock}>
            <div className={classes.stockRow}>
              <div className={classes.stockRowLeft}>
                <div
                  className={
                    square.toggle
                      ? [classes.stockRowLeftSquare, classes.toggle].join(" ")
                      : classes.stockRowLeftSquare
                  }
                  style={{ top: square.pos }}
                >
                  <span></span>
                </div>
                <div className={classes.stockRowLeftWrap}>
                  {info.map((tab) => {
                    return (
                      <div
                        key={tab.id}
                        onClick={() => {
                          let add = tab.id * 100 + 20;
                          if (window.innerWidth < 1651) {
                            add = tab.id * 100 + 0;
                          }
                          setSquare({
                            pos: add,
                            toggle: !square.toggle,
                          });
                          setInfo(
                            info.map((i) => {
                              let resActive = false;
                              if (tab.id === i.id) {
                                resActive = true;
                                return {
                                  ...i,
                                  active: resActive,
                                  leftPos: false,
                                  centerPos: true,
                                  rightPos: false,
                                };
                              } else if (i.id < tab.id) {
                                resActive = false;
                                return {
                                  ...i,
                                  active: resActive,
                                  leftPos: false,
                                  centerPos: false,
                                  rightPos: true,
                                };
                              } else if (i.id > tab.id) {
                                resActive = false;
                                return {
                                  ...i,
                                  active: resActive,
                                  leftPos: true,
                                  centerPos: false,
                                  rightPos: false,
                                };
                              }
                            })
                          );
                        }}
                        className={
                          tab.active
                            ? [
                                classes.stockRowLeftColumn,
                                classes.active,
                                "font-36",
                              ].join(" ")
                            : [classes.stockRowLeftColumn, "font-36"].join(" ")
                        }
                      >
                        {t(tab.title)}
                      </div>
                    );
                  })}
                </div>
              </div>
              <div className={classes.stockRowRight}>
                <div className={classes.stockRowRightWrapper}>
                  {info.map((i) => {
                    return (
                      <div
                        key={i.id}
                        className={
                          i.centerPos
                            ? [
                                classes.stockRowRightColumn,
                                classes.center,
                              ].join(" ")
                            : i.leftPos
                            ? [classes.stockRowRightColumn, classes.left].join(
                                " "
                              )
                            : [classes.stockRowRightColumn, classes.right].join(
                                " "
                              )
                        }
                      >
                        <div
                          className={classes.stockRowRightColumnImg}
                          style={{ backgroundImage: `url(${i.img})` }}
                        ></div>
                        {i.content.map((contentItem, index) => {
                          if (typeof contentItem === "string") {
                            return (
                              <div
                                key={index}
                                className={[
                                  classes.stockRowRightColumnText,
                                  "font-24",
                                ].join(" ")}
                              >
                                {t(contentItem)}
                              </div>
                            );
                          } else {
                            return (
                              <ul className={classes.stockRowRightColumnList} key={index}>
                                {contentItem.map((arrayItem, index) => {
                                  return (
                                    <li
                                      key={index}
                                      className={[
                                        classes.stockRowRightColumnListItem,
                                        "font-24",
                                      ].join(" ")}
                                    >
                                      {t(arrayItem)}
                                    </li>
                                  );
                                })}
                              </ul>
                            );
                          }
                        })}
                        <div className={classes.stockRowRightColumnBtn}>
                          {t(i.btnText)}
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Stock;
