import React from "react";
import classes from './TheMarkets.module.scss';
import { useTranslation } from "react-i18next";
import image_01 from '../../../assets/img/MainPage/TheMarkets/image_01.png';
import image_02 from '../../../assets/img/MainPage/TheMarkets/image_02.png';
import image_03 from '../../../assets/img/MainPage/TheMarkets/image_03.png';
import image_04 from '../../../assets/img/MainPage/TheMarkets/image_04.png';
import icon_01 from '../../../assets/img/MainPage/TheMarkets/icon_01.svg';
import icon_02 from '../../../assets/img/MainPage/TheMarkets/icon_02.svg';
import icon_03 from '../../../assets/img/MainPage/TheMarkets/icon_03.svg';
import icon_04 from '../../../assets/img/MainPage/TheMarkets/icon_04.svg';

const TheMarkets = () => {
    const { t } = useTranslation();
    return (
        <div className={classes.theMarkets}>
            <div className="container">
                <div className={classes.title}>
                    <span className="font-48">{t('the_markets_title')}</span>
                    <span className={classes.subtitle}>{t('the_markets_subtitle_01')}</span>
                </div>
                <div className={[classes.text, 'font-24'].join(' ')}>
                    {t('the_markets_text')}
                </div>
                <div className={classes.cards}>
                    <div className={[classes.col, classes.col_up].join(' ')}>
                        <div className={[classes.square, classes.square_01].join(' ')}>
                            <img className={[classes.icon, 'no-select'].join(' ')} src={icon_01} alt=''/>
                            <div className={classes.content}>
                                <div className={[classes.num, 'font-24'].join(' ')}>01</div>
                                <div className={[classes.contentText, 'font-24'].join(' ')}>
                                    {t('the_markets_card_01')}
                                </div>
                            </div>
                        </div>
                        <div className={classes.image}>
                            <img className="no-select" src={image_01} alt=''/>
                        </div>
                    </div>
                    <div className={[classes.col, classes.col_down].join(' ')}>
                        <div className={[classes.square, classes.square_02].join(' ')}>
                            <img className={[classes.icon, 'no-select'].join(' ')} src={icon_02} alt=''/>
                            <div className={classes.content}>
                                <div className={[classes.num, 'font-24'].join(' ')}>03</div>
                                <div className={[classes.contentText, 'font-24'].join(' ')}>
                                    {t('the_markets_card_03')}
                                </div>
                            </div>
                        </div>
                        <div className={classes.image}>
                            <img className="no-select" src={image_02} alt=''/>
                        </div>
                    </div>
                    <div className={[classes.col, classes.col_up].join(' ')}>
                        <div className={[classes.square, classes.square_03].join(' ')}>
                            <img className={[classes.icon, 'no-select'].join(' ')} src={icon_03} alt=''/>
                            <div className={classes.content}>
                                <div className={[classes.num, 'font-24'].join(' ')}>02</div>
                                <div className={[classes.contentText, 'font-24'].join(' ')}>
                                    {t('the_markets_card_02')}
                                </div>
                            </div>
                        </div>
                        <div className={classes.image}>
                            <img className="no-select" src={image_03} alt=''/>
                        </div>
                    </div>
                    <div className={[classes.col, classes.col_down].join(' ')}>
                        <div className={[classes.square, classes.square_04].join(' ')}>
                            <img className={[classes.icon, 'no-select'].join(' ')} src={icon_04} alt=''/>
                            <div className={classes.content}>
                                <div className={[classes.num, 'font-24'].join(' ')}>04</div>
                                <div className={[classes.contentText, 'font-24'].join(' ')}>
                                    {t('the_markets_card_04')}
                                </div>
                            </div>
                        </div>
                        <div className={classes.image}>
                            <img className="no-select" src={image_04} alt=''/>
                        </div>
                    </div>
                </div>
            </div>
            <div className={classes.cardsMob}>
                <div className={classes.cardsMobWrapper}>
                    <div className={[classes.card, classes.card_01].join(' ')}>
                        <img className={classes.cardIcon} src={icon_01} alt=''/>
                        <div className={classes.cardNum}>01</div>
                        <div className={classes.cardText}>
                            {t('the_markets_card_01')}
                        </div>
                    </div>
                    <div className={[classes.card, classes.card_02].join(' ')}>
                        <img className={classes.cardIcon} src={icon_03} alt=''/>
                        <div className={classes.cardNum}>02</div>
                        <div className={classes.cardText}>
                            {t('the_markets_card_02')}
                        </div>
                    </div>
                    <div className={[classes.card, classes.card_03].join(' ')}>
                        <img className={classes.cardIcon} src={icon_02} alt=''/>
                        <div className={classes.cardNum}>03</div>
                        <div className={classes.cardText}>
                            {t('the_markets_card_03')}
                        </div>
                    </div>
                    <div className={[classes.card, classes.card_04].join(' ')}>
                        <img className={classes.cardIcon} src={icon_04} alt=''/>
                        <div className={classes.cardNum}>04</div>
                        <div className={classes.cardText}>
                            {t('the_markets_card_04')}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default TheMarkets;
