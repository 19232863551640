import React from "react";
import classes from "./Button.module.scss";

const Button = (props) => {
  return (
    <>
      {props.nolink ?
        <div {...props} className={`${classes.button} ${props.white && classes.white} no-select`}>
          <span className={classes.btnsvg__label}>{props.children}</span>
        </div>
        :
        <a {...props} 
          href={props.login ? 'https://user.llds-group.org/login' : 'https://user.llds-group.org/signup'} 
          className={`${classes.button} ${props.white && classes.white} no-select`}
        >
          <span className={classes.btnsvg__label}>{props.children}</span>
        </a>
      }
    </>
  );
};

export default Button;
