import React, { useRef, useEffect } from "react";
import Button from "../../GlobalComponents/Button/Button";
import classes from "./Trading.module.scss";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/dist/ScrollTrigger";
import img1 from "../../../assets/img/BeginPage/4/1.png";
import { useTranslation } from "react-i18next";

const Trading = () => {
  const { t } = useTranslation();
  gsap.registerPlugin(ScrollTrigger);
  const firstRef = useRef(null);
  const objRef = useRef(null);

  useEffect(() => {
    if (window.innerWidth > 1220) {
      let lineAnimation = gsap.timeline({
        scrollTrigger: {
          trigger: firstRef.current,
          start: "top 1000",
          end: "center 600",
          scrub: true,
        },
      });
      lineAnimation.to(
        objRef.current,

        { right: 0 }
      );
    }
  }, []);

  return (
    <div className={classes.trading} ref={firstRef}>
      <div className={classes.tradingBody}>
        <div className="container">
          <div className={classes.tradingRow}>
            <div className={classes.tradingRowLeft}>
              <div
                className={[classes.tradingRowLeftTitle, "font-64"].join(" ")}
              >
                {t('trading_a_z_title')}
              </div>
              <div
                className={[classes.tradingRowLeftSubtitle, "font-40"].join(
                  " "
                )}
              >
                {t('trading_a_z_subtitle')}
              </div>
              <div
                className={[classes.tradingRowLeftText, "font-24"].join(" ")}
              >
                {t('trading_a_z_text')}
              </div>
              <div className={classes.tradingRowLeftBtn}>
                <Button>{t('trading_a_z_btn')}</Button>
              </div>
            </div>
            <div className={classes.tradingRowRight} ref={objRef}>
              <div className={classes.tradingRowRightImg}>
                <img src={img1} alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Trading;
