import React from "react";
import classes from "./Market.module.scss";
import { useTranslation } from "react-i18next";

const Market = () => {
  const { t } = useTranslation();
  return (
    <div className={classes.market}>
      <div className={classes.marketBody}>
        <div className="container">
          <div className={[classes.marketTitle, "font-64"].join(" ")}>
            {t('market_info_title')}
          </div>
          <div className={[classes.marketSubtitle, "font-24"].join(" ")}>
            {t('market_info_text_01')}
          </div>
          <div className={[classes.marketSubtitle, "font-24"].join(" ")}>
            {t('market_info_text_02')}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Market;
