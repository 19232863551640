import React, { useState } from "react";
import classes from './Newsletters.module.scss';
import Button from '../../GlobalComponents/Button/Button';
import { useTranslation } from "react-i18next";
import Cookies from "js-cookie";

const Newsletters = () => {
    const { t } = useTranslation();
    const [ email, setEmail ] = useState('');
    const lang = Cookies.get('i18next');

    return (
        <div className={[classes.container, "container"].join(' ')}>
            <div className={classes.newsletters}>
                <div className={`${classes.title} ${lang === 'en' ? 'font-64' : 'font-48'}`}>
                    {t('newsletters_and_upd_title')}
                </div>
                <div className={`${classes.textContainer} ${lang === 'en' ? 'font-24' : 'font-20-langs'}`}>
                    <div>
                        {t('newsletters_and_upd_text_01')}
                    </div>
                    <div>
                        {t('newsletters_and_upd_text_02')}
                    </div>
                </div>
                <div className={classes.bottom}>
                    <div className={classes.inputContainer}>
                        <input 
                            placeholder={t('newsletters_and_upd_email')} 
                            value={email}
                            onChange={(event) => setEmail(event.target.value)}
                        />
                        <div className={classes.btn}>
                            <Button nolink="true" white="true">{t('btn_subscribe')}</Button>
                        </div>
                    </div>
                    <div></div>
                </div>
            </div>
        </div>
    );
};

export default Newsletters;
