import React from "react";
import classes from './InvestmentStrategies.module.scss';
import Button from '../../GlobalComponents/Button/Button';
import { useTranslation } from "react-i18next";

const InvestmentStrategies = () => {
    const { t } = useTranslation();
    return (
        <div className="container">
            <div className={classes.investmentStrategies}>
                <div className={[classes.title, 'font-64'].join(' ')}>
                    {t('investment_strategies_title')}
                </div>
                <div className={classes.textContainer}>
                    <div className={[classes.left, 'font-24'].join(' ')}>
                        {t('investment_strategies_text_01')}
                    </div>
                    <div className={classes.right}>
                        <div className={classes.subtitle}>
                            {t('investment_strategies_subtitle')}
                        </div>
                        <div className={[classes.text, 'font-24'].join(' ')}>
                            {t('investment_strategies_text_02')}
                        </div>
                    </div>
                </div>
                <div className={classes.btn}>
                    <Button>{t('btn_begin_now')}</Button>
                </div>
            </div>
        </div>
    );
};

export default InvestmentStrategies;
