import React from "react";
import Button from "../../GlobalComponents/Button/Button";
import classes from "./Steps.module.scss";
import { useTranslation } from "react-i18next";

const Steps = () => {
  const { t } = useTranslation();
  return (
    <div className={classes.steps}>
      <div className={classes.stepsBody}>
        <div className="container">
          <div className={[classes.stepsTitle, "font-64"].join(" ")}>
            {t('get_started_title')}
          </div>
          <div className={classes.stepsWrapper}>
            <div className={classes.stepsRowRight}>
              <div
                className={[
                  classes.stepsRowRightContent,
                  classes.iconStep1,
                ].join(" ")}
              >
                <div
                  className={[
                    classes.stepsRowRightContentNumber,
                    "font-24",
                  ].join(" ")}
                >
                  01
                </div>
                <div
                  className={[
                    classes.stepsRowRightContentTitle,
                    "font-40",
                  ].join(" ")}
                >
                  {t('get_started_step_01_title')}
                </div>
                <div
                  className={[
                    classes.stepsRowRightContentSubtitle,
                    "font-24",
                  ].join(" ")}
                >
                  {t('get_started_step_01_text')}
                </div>
              </div>
            </div>
            <div className={classes.stepsRowLeft}>
              <div
                className={[
                  classes.stepsRowLeftContent,
                  classes.iconStep2,
                ].join(" ")}
              >
                <div
                  className={[
                    classes.stepsRowLeftContentNumber,
                    "font-24",
                  ].join(" ")}
                >
                  02
                </div>
                <div
                  className={[classes.stepsRowLeftContentTitle, "font-40"].join(
                    " "
                  )}
                >
                  {t('get_started_step_02_title')}
                </div>
                <div
                  className={[
                    classes.stepsRowLeftContentSubtitle,
                    "font-24",
                  ].join(" ")}
                >
                  {t('get_started_step_02_text')}
                </div>
              </div>
            </div>
            <div className={classes.stepsRowRight}>
              <div
                className={[
                  classes.stepsRowRightContent,
                  classes.iconStep3,
                ].join(" ")}
              >
                <div
                  className={[
                    classes.stepsRowRightContentNumber,
                    "font-24",
                  ].join(" ")}
                >
                  03
                </div>
                <div
                  className={[
                    classes.stepsRowRightContentTitle,
                    "font-40",
                  ].join(" ")}
                >
                  {t('get_started_step_03_title')}
                </div>
                <div
                  className={[
                    classes.stepsRowRightContentSubtitle,
                    "font-24",
                  ].join(" ")}
                >
                  {t('get_started_step_03_text')}
                </div>
              </div>
            </div>
            <div className={classes.stepsRowLeft}>
              <div
                className={[
                  classes.stepsRowLeftContent,
                  classes.iconStep4,
                ].join(" ")}
              >
                <div
                  className={[
                    classes.stepsRowLeftContentNumber,
                    "font-24",
                  ].join(" ")}
                >
                  04
                </div>
                <div
                  className={[classes.stepsRowLeftContentTitle, "font-40"].join(
                    " "
                  )}
                >
                  {t('get_started_step_04_title')}
                </div>
                <div
                  className={[
                    classes.stepsRowLeftContentSubtitle,
                    "font-24",
                  ].join(" ")}
                >
                  {t('get_started_step_04_text')}
                </div>
              </div>
            </div>
            <div className={classes.stepsRowRight}>
              <div
                className={[
                  classes.stepsRowRightContent,
                  classes.iconStep5,
                ].join(" ")}
              >
                <div
                  className={[
                    classes.stepsRowRightContentNumber,
                    "font-24",
                  ].join(" ")}
                >
                  05
                </div>
                <div
                  className={[
                    classes.stepsRowRightContentTitle,
                    "font-40",
                  ].join(" ")}
                >
                  {t('get_started_step_05_title')}
                </div>
                <div
                  className={[
                    classes.stepsRowRightContentSubtitle,
                    "font-24",
                  ].join(" ")}
                >
                  {t('get_started_step_05_text')}
                </div>
              </div>
            </div>
            <div className={classes.stepsRowLeft}>
              <div
                className={[
                  classes.stepsRowLeftContent,
                  classes.iconStep6,
                ].join(" ")}
              >
                <div
                  className={[
                    classes.stepsRowLeftContentNumber,
                    "font-24",
                  ].join(" ")}
                >
                  06
                </div>
                <div
                  className={[classes.stepsRowLeftContentTitle, "font-40"].join(
                    " "
                  )}
                >
                  {t('get_started_step_06_title')}
                </div>
                <div
                  className={[
                    classes.stepsRowLeftContentSubtitle,
                    "font-24",
                  ].join(" ")}
                >
                  {t('get_started_step_06_text')}
                </div>
              </div>
            </div>
          </div>
          <div className={classes.stepsBtn}>
            <Button>{t('btn_begin_now')}</Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Steps;
