import React from "react";
import classes from './OurAdvantages.module.scss';
import { useTranslation } from "react-i18next";
import card_01_img_01 from '../../../assets/img/MainPage/OurAdvantages/card_01_img_01.png';
import card_01_img_02 from '../../../assets/img/MainPage/OurAdvantages/card_01_img_02.png';
import card_01_img_03 from '../../../assets/img/MainPage/OurAdvantages/card_01_img_03.png';
import card_02_img_01 from '../../../assets/img/MainPage/OurAdvantages/card_02_img_01.png';
import card_02_img_02 from '../../../assets/img/MainPage/OurAdvantages/card_02_img_02.png';
import card_02_img_03 from '../../../assets/img/MainPage/OurAdvantages/card_02_img_03.png';
import card_03_img_01 from '../../../assets/img/MainPage/OurAdvantages/card_03_img_01.png';
import card_03_img_02 from '../../../assets/img/MainPage/OurAdvantages/card_03_img_02.png';
import card_03_img_03 from '../../../assets/img/MainPage/OurAdvantages/card_03_img_03.png';

const OurAdvantages = () => {
    const { t } = useTranslation();
    return (
        <div className="container">
            <div id="ourAdvantagesWr" className={classes.ourAdvantages}>
                <div className={[classes.title, 'font-64'].join(' ')}>
                    {t('our_advantages_title')}
                </div>
                <div className={classes.cards}>
                    <div className={[classes.card, classes.card_01].join(' ')}>
                        <div className={[classes.images, classes.images_01].join(' ')}>
                            <div className={classes.circle}></div>
                            <img className={[classes.img_01, 'no-select'].join(' ')} src={card_01_img_01} alt=''/>
                            <img className={[classes.img_02, 'no-select'].join(' ')} src={card_01_img_02} alt=''/>
                            <img className={[classes.img_03, 'no-select'].join(' ')} src={card_01_img_03} alt=''/>
                        </div>
                        <div className={[classes.title, 'font-32'].join(' ')}>
                            {t('our_advantages_card_title_01')}
                        </div>
                        <div className={classes.text}>
                            {t('our_advantages_card_text_01')}
                        </div>
                    </div>
                    <div className={[classes.card, classes.card_02].join(' ')}>
                        <div className={[classes.images, classes.images_02].join(' ')}>
                            <div className={classes.circle}></div>
                            <img className={[classes.img_01, 'no-select'].join(' ')} src={card_02_img_01} alt=''/>
                            <img className={[classes.img_02, 'no-select'].join(' ')} src={card_02_img_02} alt=''/>
                            <img className={[classes.img_03, 'no-select'].join(' ')} src={card_02_img_03} alt=''/>
                        </div>
                        <div className={[classes.title, 'font-32'].join(' ')}>
                            {t('our_advantages_card_title_02')}
                        </div>
                        <div className={classes.text}>
                            {t('our_advantages_card_text_02')}
                        </div>
                    </div>
                    <div className={[classes.card, classes.card_03].join(' ')}>
                        <div className={[classes.images, classes.images_03].join(' ')}>
                            <div className={classes.circle}></div>
                            <img className={[classes.img_01, 'no-select'].join(' ')} src={card_03_img_01} alt=''/>
                            <img className={[classes.img_02, 'no-select'].join(' ')} src={card_03_img_02} alt=''/>
                            <img className={[classes.img_03, 'no-select'].join(' ')} src={card_03_img_03} alt=''/>
                        </div>
                        <div className={[classes.title, 'font-32'].join(' ')}>
                            {t('our_advantages_card_title_03')}
                        </div>
                        <div className={classes.text}>
                            {t('our_advantages_card_text_03')}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default OurAdvantages;
