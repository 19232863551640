import React, { useEffect } from "react";
import Columns from "../../components/TradingSpace/Columns/Columns";
import TradeSpace from "../../components/TradingSpace/TradeSpace/TradeSpace";
import classes from "./TradingSpace.module.scss";

import Bottom from "../../components/BeginComponents/Bottom/Bottom";
import Steps from "../../components/TradingSpace/Steps/Steps";
import Market from "../../components/TradingSpace/Market/Market";
import OpenAccount from "../../components/TradingSpace/OpenAccount/OpenAccount";

const TradingSpace = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className={classes.trading}>
      <TradeSpace />
      <OpenAccount />
      {/* <Columns /> */}
      <Steps />
      <Market />
      <Bottom />
    </div>
  );
};

export default TradingSpace;
