import logo from '../assets/img/Global/logo.png';
// main page
import map from '../assets/img/MainPage/Banner/map.png';
import chart from '../assets/img/MainPage/Banner/chart.png';
import bottom_floor from '../assets/img/MainPage/Banner/bottom_floor.png';
import tower_left_with_trees from '../assets/img/MainPage/Banner/tower_left_with_trees.png';
import tower_left_towers from '../assets/img/MainPage/Banner/tower_left_towers.png';
import tower_left_hight from '../assets/img/MainPage/Banner/tower_left_hight.png';
import trees from '../assets/img/MainPage/Banner/trees.png';
import tower_right_towers from '../assets/img/MainPage/Banner/tower_right_towers.png';
import tower_right_tower from '../assets/img/MainPage/Banner/tower_right_tower.png';
import bull from '../assets/img/MainPage/Banner/bull.png';
import bear from '../assets/img/MainPage/Banner/bear.png';
// begin now
import begin_banner_02 from '../assets/img/BeginPage/1/bg1.png';
import begin_banner_04 from '../assets/img/BeginPage/1/bgMob1.png';
import begin_banner_05 from '../assets/img/BeginPage/1/2.png';
import begin_banner_06 from '../assets/img/BeginPage/1/1.png';
import begin_banner_07 from '../assets/img/BeginPage/1/bullMob.png';
// trading space
import trading_banner_02 from '../assets/img/TradingSpace/1/bg1.png';
import trading_banner_05 from '../assets/img/TradingSpace/1/2.png';
import trading_banner_06 from '../assets/img/TradingSpace/1/1.png';
import trading_banner_07 from '../assets/img/TradingSpace/1/bullMob.png';
// education
import edu_arrow from '../assets/img/Education/banner_arrow.png';
import edu_bull from '../assets/img/Education/banner_bull.png';
import edu_support from '../assets/img/Education/support_resistance_image.png';
// about us
import about_03 from '../assets/img/AboutUsPage/top-index.png';
import about_04 from '../assets/img/AboutUsPage/wolf.png';

export const images = [
    logo,
    map,
    chart,
    bottom_floor,
    tower_left_with_trees,
    tower_left_towers,
    tower_left_hight,
    trees,
    tower_right_towers,
    tower_right_tower,
    bull,
    bear,
    begin_banner_02,
    begin_banner_04,
    begin_banner_05,
    begin_banner_06,
    begin_banner_07,
    trading_banner_02,
    trading_banner_05,
    trading_banner_06,
    trading_banner_07,
    edu_arrow,
    edu_bull,
    edu_support,
    about_03,
    about_04,
];