import React, { useEffect } from "react";
import classes from "./AboutUs.module.scss";
import Banner from "../../components/AboutUs/Banner/Banner";
import AboutLalalaTrade from "../../components/AboutUs/AboutLalalaTrade/AboutLalalaTrade";
import BigestProfit from "../../components/AboutUs/BigestProfit/BigestProfit";
import AnyQuestions from "../../components/AboutUs/AnyQuestions/AnyQuestions";
import OurStory from "../../components/AboutUs/OurStory/OurStory";

const AboutUs = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className={classes.aboutUsWrap}>
      <Banner />
      <OurStory />
      {/* <AboutLalalaTrade /> */}
      <BigestProfit />
      <AnyQuestions />
    </div>
  );
  };
  
  export default AboutUs;