import React, { useEffect } from "react";
import Bottom from "../../components/BeginComponents/Bottom/Bottom";
import Newsletters from "../../components/BeginComponents/Newsletters/Newsletters";
import Ready from "../../components/BeginComponents/Ready/Ready";
import Slider from "../../components/BeginComponents/Slider/Slider";
import Stock from "../../components/BeginComponents/Stock/Stock";
import Trading from "../../components/BeginComponents/Trading/Trading";
import classes from "./BeginNow.module.scss";
const BeginNow = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className={classes.begin}>
      <Ready />
      <Slider />
      <Stock />
      <Trading />
      <Newsletters />
      <Bottom />
    </div>
  );
};

export default BeginNow;
