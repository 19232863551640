import React from "react";
import Button from "../../GlobalComponents/Button/Button";
import classes from "./Bottom.module.scss";
import { useTranslation } from "react-i18next";

const Bottom = () => {
  const { t } = useTranslation();
  return (
    <div className={classes.bottom}>
      <div className={classes.bottomBody}>
        <div className="container">
          <div className={classes.bottomWrapper}>
            <div className={classes.bottomContent}>
              <div className={[classes.bottomTitle, "font-64"].join(" ")}>
                {t('newsletters_title')}
              </div>
              <div className={[classes.bottomSubtitle, "font-40"].join(" ")}>
                {t('newsletters_subtitle')}
              </div>
              <div className={[classes.bottomText, "font-24"].join(" ")}>
                {t('newsletters_text')}
              </div>
              <div className={classes.bottomForm}>
                <div className={classes.bottomFormEmail}>
                  <input
                    className="font-24"
                    type="text"
                    placeholder={t('newsletters_your_email')}
                  />
                </div>
                <div className={classes.bottomFormBtn}>
                  <Button nolink="true" white="true">{t('btn_subscribe')}</Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Bottom;
