import React, { useState, useEffect, useRef } from "react";
import classes from './AutomaticTrading.module.scss';
import Button from '../../../components/GlobalComponents/Button/Button';
import $ from 'jquery';
import { useTranslation } from "react-i18next";

const AutomaticTrading = () => {
    const { t } = useTranslation();
    const tab_01_ref = useRef(null);
    const tab_02_ref = useRef(null);
    const [ tab01Active, setTab01Active ] = useState(false);
    const [ tab02Active, setTab02Active ] = useState(false);
    useEffect(() => {
        $(tab_01_ref.current).slideUp(0);
        $(tab_02_ref.current).slideUp(0);
    }, []);
    return (
        <div className="container">
            <div className={classes.automaticTrading}>
                <div className={[classes.title, 'font-64'].join(' ')}>
                    {t('automatic_trading_title')}
                </div>
                <div className={[classes.text, 'font-24'].join(' ')}>
                    {t('automatic_trading_text')}
                </div>
                <div className={classes.btn}>
                    <Button>{t('btn_begin_now')}</Button>
                </div>
                <div className={classes.tabs}>
                    <div 
                        className={classes.tab}
                        onClick={() => {
                            $(tab_01_ref.current).slideToggle(400);
                            setTab01Active(value => !value);
                        }}    
                    >
                        <div className={classes.tabTitle}>
                            <div className={classes.tabTitleText}>
                                {t('automatic_trading_tab_title_01')}
                            </div>
                            <div className={`${classes.tabTitleStatus} ${tab01Active && classes.activeStatus}`}></div>
                        </div>
                        <div ref={tab_01_ref} className={[classes.tabContent, 'font-24'].join(' ')}>
                            <div>
                                {t('automatic_trading_tab_text_01')}
                            </div>
                        </div>
                    </div>
                    <div 
                        className={classes.tab}
                        onClick={() => {
                            $(tab_02_ref.current).slideToggle(400);
                            setTab02Active(value => !value);
                        }}    
                    >
                        <div className={classes.tabTitle}>
                            <div className={classes.tabTitleText}>
                                {t('automatic_trading_tab_title_02')}
                            </div>
                            <div className={`${classes.tabTitleStatus} ${tab02Active && classes.activeStatus}`}></div>
                        </div>
                        <div ref={tab_02_ref} className={[classes.tabContent, 'font-24'].join(' ')}>
                            <div>
                                {t('automatic_trading_tab_text_02')}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AutomaticTrading;
