import React from "react";
import classes from './Awards.module.scss';
import { useTranslation } from "react-i18next";
import award_01 from '../../../assets/img/MainPage/Awards/award_01.png';
import award_02 from '../../../assets/img/MainPage/Awards/award_02.png';
import award_03 from '../../../assets/img/MainPage/Awards/award_03.png';

const Awards = () => {
    const { t } = useTranslation();
    return (
        <div className={["container", classes.cont].join(' ')}>
            <div className={[classes.awards, 'no-select'].join(' ')}>
                <img src={award_01} alt=''/>
                <img src={award_02} alt=''/>
                <img src={award_03} alt=''/>
            </div>
        </div>
    );
};

export default Awards;
